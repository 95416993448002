import { useContext, useEffect, useState } from "react";
import {
  Flex,
  Text,
  Button,
  TextField,
  SelectField,
  Card,
  Alert,
} from "@aws-amplify/ui-react";

// Gadget Components
import {
  MapAndAddressSelector,
  PzTooltip,
  TradeSupervisionSelector,
} from "./index";

import { PzPrimeContext } from "../Context/ProposalContext";
import { useNavigate } from "react-router-dom";

const ProjectDetails = (props) => {
  const { calledFromModal } = props;
  // let requiredFields = getRequiredFieldStates();
  const navig = useNavigate();

  const {
    address,
    discipline,
    distanceMatrixData,
    projectName,
    setProjectName,
    setCurrentStage,
    setBuildingArea,
    projectArea,
    setProjectArea,
    numberOfBuildings,
    setNumberOfBuildings,
    setConstructionType,
    constructionType,
    setActiveStage,
    stageValue,
    currentStage,
    colorMode,
    changeInInput,
    setChangeInInput,
    checkMinimumRequirements,
    getRequiredFieldStates,
    projectId,
    openMissingFields,
  } = useContext(PzPrimeContext);

  const customCss = `
      .cursor{
          cursor:pointer;
      }
      .layerTop{
        z-index:99;
      }
      .amplify-select {
        color:#2e3436;
      }
`;

  const darkCss = `
.amplify-select {
  background:#2e3436;
}
`;

  const [isTradeSelected, setIsTradeSelected] = useState(true);

  const [isRequirementMet, setisRequirementMet] = useState(true);

  const [isWarningVisible, setIsWarningVisible] = useState(false);
  const [requiredFields, setRequiredFields] = useState({
    projectName: false,
    address: false,
    discipline: false,
    projectArea: false,
    numberOfBuildings: false,
  });

  useEffect(() => {
    const fetchRequiredFieldStates = async () => {
      const states = await getRequiredFieldStates(projectId);
      setRequiredFields(states);
    };
    if (openMissingFields) {
      fetchRequiredFieldStates();
    }
  }, [getRequiredFieldStates]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      navig("/");
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  function changeInValue(e) {
    if (e.target.value < 0 || e.target.value === "") e.target.value = 0;
    if (e.target.name === "buildingArea")
      setBuildingArea(parseInt(e.target.value));
    if (e.target.name === "numberOfBuildings")
      if (!/^[0-9]+$/.test(e.target.value)) alert("enter integers");
      else setNumberOfBuildings(parseInt(e.target.value));
    if (e.target.name === "projectArea")
      setProjectArea(parseInt(e.target.value));
    if (!openMissingFields) {
      setChangeInInput(!changeInInput);
    }

    setIsWarningVisible(false);
  }
  if (!calledFromModal) {
    setCurrentStage("projectDetails");
    setActiveStage(stageValue[currentStage] - 1);
  }

  function checkProjectDetailsRequirements() {
    let minimumRequirementsFlag = checkMinimumRequirements();
    let tradeFlag = 0;
    for (const trade in discipline) {
      if (discipline[trade] === 1) {
        tradeFlag = 1;
        break;
      }
    }
    if (tradeFlag === 0) {
      setIsTradeSelected(false);
    } else {
      setIsTradeSelected(true);
    }
    console.log("minimumRequirementsFlag", minimumRequirementsFlag);
    if (minimumRequirementsFlag) {
      setisRequirementMet(false);
      setIsWarningVisible(true);
    } else {
      if (!openMissingFields) {
        setChangeInInput(!changeInInput);
      }
      navig("/market-selector");
      setisRequirementMet(true);
    }
  }

  return (
    <Flex maxWidth={calledFromModal ? "100%" : "74%"}>
      <style>{colorMode === "dark" ? darkCss : customCss}</style>
      <Card
        display={"flex"}
        direction={"column"}
        padding={"25px"}
        borderRadius="15px"
        alignItems="flex-start"
        justifyContent={"flex-start"}
        gap="20px"
        width={"100%"}
        minWidth={"300px"}
        height={"100%"}
        border={colorMode === "dark" ? "none" : "1px solid #c5c5c5"}
        margin={calledFromModal? "" :"20px"}
      >
        {((calledFromModal && requiredFields.projectName) ||
          !calledFromModal) && (
          <Card
            direction={"column"}
            width={"100%"}
            padding={"15px"}
            border={colorMode === "dark" ? "none" : "1px solid #c5c5c5"}
            backgroundColor={colorMode === "dark" && "#2e3436"}
            borderRadius={"10px"}
          >
            <Text fontSize={"22px"} marginBottom={"10px"}>
              Project Name
            </Text>
            <TextField
              errorMessage="Project Name not entered"
              hasError={!isRequirementMet && projectName.length === 0}
              placeholder="Enter your project name"
              value={projectName}
              labelHidden
              onChange={(e) => {
                setIsWarningVisible(false);

                setProjectName(e.target.value);
                if (!openMissingFields) {
                  setChangeInInput(!changeInInput);
                }
              }}
            />
          </Card>
        )}
        {((calledFromModal && requiredFields.address) || !calledFromModal) && (
          <Flex
            direction={"column"}
            width={"100%"}
            borderRadius="10px"
            backgroundColor={colorMode === "dark" && "#2e3436"}
            border={colorMode === "dark" ? "none" : "1px solid #c5c5c5"}
            padding={"15px"}
            gap={"20px"}
          >
            <MapAndAddressSelector
              setIsWarningVisible={setIsWarningVisible}
              isInstantQuoteForm={false}
              isRequirementMet={isRequirementMet}
            />
          </Flex>
        )}
        {((calledFromModal && requiredFields.discipline) ||
          !calledFromModal) && (
          <Flex
            width={"100%"}
            borderRadius="10px"
            backgroundColor={colorMode === "dark" && "#2e3436"}
            border={colorMode === "dark" ? "none" : "1px solid #c5c5c5"}
          >
            <TradeSupervisionSelector
              setIsWarningVisible={setIsWarningVisible}
              isInstantQuoteForm={false}
              isRequirementMet={isRequirementMet}
              isTradeSelected={isTradeSelected}
              setIsTradeSelected={setIsTradeSelected}
            />
          </Flex>
        )}
        {!calledFromModal && (
          <Card
            display={"flex"}
            padding={"15px"}
            backgroundColor={colorMode === "dark" && "#2e3436"}
            border={colorMode === "dark" ? "none" : "1px solid #c5c5c5"}
            borderRadius={"10px"}
            direction={"column"}
            width={"100%"}
          >
            <Flex justifyContent={"space-between"} width={"100%"}>
              <Text fontSize={"22px"} marginBottom={"10px"}>
                Construction Type
              </Text>
              <PzTooltip
                place={"top"}
                isIcon={true}
                text="Select your preferred Construction Type"
              />
            </Flex>
            <SelectField
              labelHidden
              onChange={(e) => {
                setConstructionType(e.target.value);
                if (!openMissingFields) {
                  setChangeInInput(!changeInInput);
                }
              }}
              value={constructionType}
            >
              <option value="New Construction">New Construction</option>
              <option value="Adaptive Reuse/Clean Slate">
                Adaptive Reuse/Clean Slate
              </option>
              <option value="Adaptive Reuse/Recycled System">
                Adaptive Reuse/Recycled Systems
              </option>
            </SelectField>
          </Card>
        )}

        {((calledFromModal &&
          (requiredFields.projectArea || requiredFields.numberOfBuildings)) ||
          !calledFromModal) && (
          <Flex
            direction={"column"}
            width={"100%"}
            padding={"15px"}
            backgroundColor={colorMode === "dark" && "#2e3436"}
            border={colorMode === "dark" ? "none" : "1px solid #c5c5c5"}
            borderRadius={"10px"}
          >
            <Flex justifyContent={"space-between"} width={"100%"}>
              <Text fontSize={"22px"}>Project Scope</Text>
              <PzTooltip
                place={"top"}
                isIcon={true}
                text="Enter the details of the project: total building area, no. of buildings, etc."
              />
            </Flex>
            <Flex
              padding="20px 20px"
              direction="column"
              justifyContent="center"
              gap="10px"
              width={"100%"}
              border={"1px solid #AEB3B7"}
              borderRadius={"10px"}
            >
              {((calledFromModal && requiredFields.projectArea) ||
                !calledFromModal) && (
                <TextField
                  label="Approximate Project Area (sqft)"
                  placeholder="Enter your project Area (in Sq.ft)"
                  onWheel={(e) => e.target.blur()}
                  hasError={!isRequirementMet && projectArea === 0}
                  errorMessage="Kindly enter the project area"
                  onFocus={(e) => e.target.select()}
                  type="number"
                  name="projectArea"
                  value={projectArea ? projectArea : ""}
                  onChange={(e) => changeInValue(e)}
                />
              )}
              {((calledFromModal && requiredFields.numberOfBuildings) ||
                !calledFromModal) && (
                <TextField
                  label="Number of Buildings"
                  placeholder="Enter the No. of Buildings"
                  onWheel={(e) => e.target.blur()}
                  hasError={!isRequirementMet && numberOfBuildings === 0}
                  errorMessage="Kindly enter the project area"
                  onFocus={(e) => e.target.select()}
                  type="number"
                  name="numberOfBuildings"
                  value={numberOfBuildings ? numberOfBuildings : ""}
                  onChange={(e) => changeInValue(e)}
                />
              )}
            </Flex>
          </Flex>
        )}
      </Card>
      {isWarningVisible && (
        <Alert
          position={"fixed"}
          bottom={"100px"}
          right={"50px"}
          variation="warning"
          transition="all 0.9s ease"
        >
          Please fill all highlighted fields to continue
        </Alert>
      )}

      {!calledFromModal && (
        <Button
          color={"#fff"}
          className="layerTop"
          position={"fixed"}
          bottom={"25px"}
          right={"100px"}
          backgroundColor={"#40AABF"}
          width={"200px"}
          margin={"10px 30px"}
          onClick={() => {
            checkProjectDetailsRequirements();
          }}
        >
          Next Step
        </Button>
      )}
    </Flex>
  );
};

export default ProjectDetails;
