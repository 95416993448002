export const schema = {
    "models": {
        "PromoCode": {
            "name": "PromoCode",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "code": {
                    "name": "code",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "requirements": {
                    "name": "requirements",
                    "isArray": false,
                    "type": {
                        "nonModel": "PromoRequirements"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "promoClass": {
                    "name": "promoClass",
                    "isArray": false,
                    "type": {
                        "enum": "PromoClass"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "discount": {
                    "name": "discount",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "disablePromoCode": {
                    "name": "disablePromoCode",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "expirationDate": {
                    "name": "expirationDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "applyExpirationDate": {
                    "name": "applyExpirationDate",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "uses": {
                    "name": "uses",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "PromoCodes",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Admins"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "public",
                                "provider": "apiKey",
                                "operations": [
                                    "read",
                                    "update"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Space": {
            "name": "Space",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "templateID": {
                    "name": "templateID",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "imageKey": {
                    "name": "imageKey",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "className": {
                    "name": "className",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "customName": {
                    "name": "customName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "priceSheet": {
                    "name": "priceSheet",
                    "isArray": false,
                    "type": {
                        "nonModel": "PriceSheet"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "areas": {
                    "name": "areas",
                    "isArray": true,
                    "type": {
                        "nonModel": "Area"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "auxInputs": {
                    "name": "auxInputs",
                    "isArray": true,
                    "type": {
                        "nonModel": "SpaceInput"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "costFlags": {
                    "name": "costFlags",
                    "isArray": true,
                    "type": {
                        "nonModel": "CostFlag"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "projectSpacesId": {
                    "name": "projectSpacesId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Spaces",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "gsi-Project.spaces",
                        "fields": [
                            "projectSpacesId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "public",
                                "provider": "apiKey",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "BasePrice": {
            "name": "BasePrice",
            "fields": {
                "date": {
                    "name": "date",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "author": {
                    "name": "author",
                    "isArray": false,
                    "type": "AWSEmail",
                    "isRequired": true,
                    "attributes": []
                },
                "disciplines": {
                    "name": "disciplines",
                    "isArray": false,
                    "type": {
                        "nonModel": "DisciplinePricing"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "globalMultiplier": {
                    "name": "globalMultiplier",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "globalMultiplierOnSetup": {
                    "name": "globalMultiplierOnSetup",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "taxRate": {
                    "name": "taxRate",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "BasePrices",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "fields": [
                            "date"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Admins"
                                ],
                                "operations": [
                                    "read",
                                    "create"
                                ]
                            },
                            {
                                "allow": "public",
                                "provider": "apiKey",
                                "operations": [
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Proposal": {
            "name": "Proposal",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "projectId": {
                    "name": "projectId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "proposalData": {
                    "name": "proposalData",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "documentHtml": {
                    "name": "documentHtml",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "pdfKey": {
                    "name": "pdfKey",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "Proposals",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Admins"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "public",
                                "provider": "apiKey",
                                "operations": [
                                    "read",
                                    "create"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "ProposalTemplate": {
            "name": "ProposalTemplate",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "date": {
                    "name": "date",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "author": {
                    "name": "author",
                    "isArray": false,
                    "type": "AWSEmail",
                    "isRequired": true,
                    "attributes": []
                },
                "filename": {
                    "name": "filename",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "ProposalTemplates",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Admins"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "public",
                                "provider": "apiKey",
                                "operations": [
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "SpaceTemplate": {
            "name": "SpaceTemplate",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "templateName": {
                    "name": "templateName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "imageKey": {
                    "name": "imageKey",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "areas": {
                    "name": "areas",
                    "isArray": true,
                    "type": {
                        "nonModel": "Area"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "priceSheet": {
                    "name": "priceSheet",
                    "isArray": false,
                    "type": {
                        "nonModel": "PriceSheet"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "auxInputs": {
                    "name": "auxInputs",
                    "isArray": true,
                    "type": {
                        "nonModel": "SpaceInput"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "costFlags": {
                    "name": "costFlags",
                    "isArray": true,
                    "type": {
                        "model": "SpaceCostFlagRelationships"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "spaceTemplate"
                        ]
                    }
                },
                "markets": {
                    "name": "markets",
                    "isArray": true,
                    "type": {
                        "enum": "Market"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "instantQuoteSpace": {
                    "name": "instantQuoteSpace",
                    "isArray": false,
                    "type": {
                        "model": "InstantQuoteSpace"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": [
                            "id"
                        ],
                        "targetNames": [
                            "spaceTemplateInstantQuoteSpaceId"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "spaceTemplateInstantQuoteSpaceId": {
                    "name": "spaceTemplateInstantQuoteSpaceId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "SpaceTemplates",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Admins"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "public",
                                "provider": "apiKey",
                                "operations": [
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "InstantQuoteSpace": {
            "name": "InstantQuoteSpace",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "spaceTemplateName": {
                    "name": "spaceTemplateName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "spaceTemplate": {
                    "name": "spaceTemplate",
                    "isArray": false,
                    "type": {
                        "model": "SpaceTemplate"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "instantQuoteSpaceSpaceTemplateId"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "instantQuoteSpaceSpaceTemplateId": {
                    "name": "instantQuoteSpaceSpaceTemplateId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "InstantQuoteSpaces",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Admins"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "public",
                                "provider": "apiKey",
                                "operations": [
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "CostFlagTemplate": {
            "name": "CostFlagTemplate",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "featureTitle": {
                    "name": "featureTitle",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "dataType": {
                    "name": "dataType",
                    "isArray": false,
                    "type": {
                        "enum": "DataType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "checkedValue": {
                    "name": "checkedValue",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "intValue": {
                    "name": "intValue",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "alertLevel": {
                    "name": "alertLevel",
                    "isArray": false,
                    "type": {
                        "enum": "AlertLevel"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "alertTitle": {
                    "name": "alertTitle",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "alertBody": {
                    "name": "alertBody",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "spaces": {
                    "name": "spaces",
                    "isArray": true,
                    "type": {
                        "model": "SpaceCostFlagRelationships"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "costFlagTemplate"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "CostFlagTemplates",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Admins"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "public",
                                "provider": "apiKey",
                                "operations": [
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Organization": {
            "name": "Organization",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "billingEmail": {
                    "name": "billingEmail",
                    "isArray": false,
                    "type": "AWSEmail",
                    "isRequired": false,
                    "attributes": []
                },
                "address": {
                    "name": "address",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "tagLine": {
                    "name": "tagLine",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "Organizations",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "public",
                                "provider": "apiKey",
                                "operations": [
                                    "read",
                                    "create"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Project": {
            "name": "Project",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "priceSheet": {
                    "name": "priceSheet",
                    "isArray": false,
                    "type": {
                        "nonModel": "PriceSheet"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "projectArea": {
                    "name": "projectArea",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "numberOfBuildings": {
                    "name": "numberOfBuildings",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "totalBuildingsArea": {
                    "name": "totalBuildingsArea",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "promoCodes": {
                    "name": "promoCodes",
                    "isArray": true,
                    "type": {
                        "nonModel": "PromoCodeReference"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "markets": {
                    "name": "markets",
                    "isArray": true,
                    "type": {
                        "enum": "Market"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "pipedriveID": {
                    "name": "pipedriveID",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "editors": {
                    "name": "editors",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "authorizerName": {
                    "name": "authorizerName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "authorizerEmail": {
                    "name": "authorizerEmail",
                    "isArray": false,
                    "type": "AWSEmail",
                    "isRequired": false,
                    "attributes": []
                },
                "userEmail": {
                    "name": "userEmail",
                    "isArray": false,
                    "type": "AWSEmail",
                    "isRequired": false,
                    "attributes": []
                },
                "constructionType": {
                    "name": "constructionType",
                    "isArray": false,
                    "type": {
                        "enum": "ConstructionType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "tradeSupervisions": {
                    "name": "tradeSupervisions",
                    "isArray": true,
                    "type": {
                        "enum": "TradeSupervision"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "packageLevel": {
                    "name": "packageLevel",
                    "isArray": false,
                    "type": {
                        "enum": "ContractPackageName"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "location": {
                    "name": "location",
                    "isArray": false,
                    "type": {
                        "nonModel": "ProjectLocation"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "spaces": {
                    "name": "spaces",
                    "isArray": true,
                    "type": {
                        "model": "Space"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "projectSpacesId"
                        ]
                    }
                },
                "spaceDict": {
                    "name": "spaceDict",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "proposals": {
                    "name": "proposals",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "isInstantQuote": {
                    "name": "isInstantQuote",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "projectDescription": {
                    "name": "projectDescription",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "userName": {
                    "name": "userName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "userPhoneNumber": {
                    "name": "userPhoneNumber",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "dealID": {
                    "name": "dealID",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "Projects",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "editors",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Admins"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "public",
                                "provider": "apiKey",
                                "operations": [
                                    "update",
                                    "create"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "InstantQuote": {
            "name": "InstantQuote",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "location": {
                    "name": "location",
                    "isArray": false,
                    "type": {
                        "nonModel": "ProjectLocation"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "tradeSupervisions": {
                    "name": "tradeSupervisions",
                    "isArray": true,
                    "type": {
                        "enum": "TradeSupervision"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "buildingSF": {
                    "name": "buildingSF",
                    "isArray": false,
                    "type": {
                        "nonModel": "BuildingSF"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "userDetails": {
                    "name": "userDetails",
                    "isArray": false,
                    "type": {
                        "nonModel": "UserDetails"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "projectDescription": {
                    "name": "projectDescription",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "version": {
                    "name": "version",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "pipedriveID": {
                    "name": "pipedriveID",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "InstantQuotes",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "public",
                                "provider": "apiKey",
                                "operations": [
                                    "read",
                                    "update",
                                    "create"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "SpaceCostFlagRelationships": {
            "name": "SpaceCostFlagRelationships",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "spaceTemplateId": {
                    "name": "spaceTemplateId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "costFlagTemplateId": {
                    "name": "costFlagTemplateId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "spaceTemplate": {
                    "name": "spaceTemplate",
                    "isArray": false,
                    "type": {
                        "model": "SpaceTemplate"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "spaceTemplateId"
                        ]
                    }
                },
                "costFlagTemplate": {
                    "name": "costFlagTemplate",
                    "isArray": false,
                    "type": {
                        "model": "CostFlagTemplate"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "costFlagTemplateId"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "SpaceCostFlagRelationships",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "bySpaceTemplate",
                        "fields": [
                            "spaceTemplateId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byCostFlagTemplate",
                        "fields": [
                            "costFlagTemplateId"
                        ]
                    }
                }
            ]
        }
    },
    "enums": {
        "ConstructionType": {
            "name": "ConstructionType",
            "values": [
                "GROUND_UP",
                "ADAPTIVE_REUSE_CLEAN_SLATE",
                "ADAPTIVE_REUSE_RECYCLED_SYSTEMS"
            ]
        },
        "ContractPackageName": {
            "name": "ContractPackageName",
            "values": [
                "GIST",
                "MINIMALIST",
                "COLLABORATOR",
                "PERFECTIONIST",
                "TWO_WEEK",
                "MINI"
            ]
        },
        "TradeSupervision": {
            "name": "TradeSupervision",
            "values": [
                "MECH",
                "ELEC",
                "PLUMB",
                "GAS"
            ]
        },
        "Market": {
            "name": "Market",
            "values": [
                "MULTIFAMILY_MIXED_USE",
                "OFFICE_RETAIL",
                "FOOD_BEVERAGE",
                "HEALTHCARE",
                "CORE_SHELL",
                "WAREHOUSE_STORAGE",
                "SINGLE_FAMILY_RESIDENTIAL",
                "RELIGIOUS_CULTURAL",
                "EDUCATION_DAYCARE",
                "FACTORY_INDUSTRIAL_GARAGE",
                "THEATERS_EVENT_SPACES",
                "OTHER"
            ]
        },
        "DataType": {
            "name": "DataType",
            "values": [
                "INTEGER",
                "CHECKBOX",
                "CHECKBOX_W_INTEGER"
            ]
        },
        "AlertLevel": {
            "name": "AlertLevel",
            "values": [
                "SUCCESS",
                "WARNING",
                "ERROR"
            ]
        },
        "PricingModel": {
            "name": "PricingModel",
            "values": [
                "PROJECT_BASE_FEES",
                "RELATIVE_LINEAR",
                "SPACE_SETUP",
                "COMPRESSED"
            ]
        },
        "PriceClass": {
            "name": "PriceClass",
            "values": [
                "ADJUSTMENT",
                "DISCIPLINE",
                "TAX"
            ]
        },
        "PromoClass": {
            "name": "PromoClass",
            "values": [
                "PERCENTAGE_DISCOUNT",
                "FLAT_DISCOUNT"
            ]
        }
    },
    "nonModels": {
        "ProjectLocation": {
            "name": "ProjectLocation",
            "fields": {
                "latitude": {
                    "name": "latitude",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "longitude": {
                    "name": "longitude",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "address": {
                    "name": "address",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "driveDistance": {
                    "name": "driveDistance",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "driveDuration": {
                    "name": "driveDuration",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "AppliedDiscount": {
            "name": "AppliedDiscount",
            "fields": {
                "code": {
                    "name": "code",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "discount": {
                    "name": "discount",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "PriceSheet": {
            "name": "PriceSheet",
            "fields": {
                "pricingModel": {
                    "name": "pricingModel",
                    "isArray": false,
                    "type": {
                        "enum": "PricingModel"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "mech": {
                    "name": "mech",
                    "isArray": true,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "elec": {
                    "name": "elec",
                    "isArray": true,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "plum": {
                    "name": "plum",
                    "isArray": true,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "mechTotal": {
                    "name": "mechTotal",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "elecTotal": {
                    "name": "elecTotal",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "plumTotal": {
                    "name": "plumTotal",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "initialSubtotal": {
                    "name": "initialSubtotal",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "promoDiscounts": {
                    "name": "promoDiscounts",
                    "isArray": true,
                    "type": {
                        "nonModel": "AppliedDiscount"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "subtotalAfterPromos": {
                    "name": "subtotalAfterPromos",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "taxes": {
                    "name": "taxes",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "total": {
                    "name": "total",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "Area": {
            "name": "Area",
            "fields": {
                "areaTitle": {
                    "name": "areaTitle",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "area": {
                    "name": "area",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "instantQuoteWeight": {
                    "name": "instantQuoteWeight",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "priceSheet": {
                    "name": "priceSheet",
                    "isArray": false,
                    "type": {
                        "nonModel": "PriceSheet"
                    },
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "CostFlag": {
            "name": "CostFlag",
            "fields": {
                "featureTitle": {
                    "name": "featureTitle",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "dataType": {
                    "name": "dataType",
                    "isArray": false,
                    "type": {
                        "enum": "DataType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "checkedValue": {
                    "name": "checkedValue",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "intValue": {
                    "name": "intValue",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "alertLevel": {
                    "name": "alertLevel",
                    "isArray": false,
                    "type": {
                        "enum": "AlertLevel"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "alertTitle": {
                    "name": "alertTitle",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "alertBody": {
                    "name": "alertBody",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "SpaceInput": {
            "name": "SpaceInput",
            "fields": {
                "inputTitle": {
                    "name": "inputTitle",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "dataType": {
                    "name": "dataType",
                    "isArray": false,
                    "type": {
                        "enum": "DataType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "checkedValue": {
                    "name": "checkedValue",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "intValue": {
                    "name": "intValue",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "alertLevel": {
                    "name": "alertLevel",
                    "isArray": false,
                    "type": {
                        "enum": "AlertLevel"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "alertTitle": {
                    "name": "alertTitle",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "alertBody": {
                    "name": "alertBody",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "BuildingSF": {
            "name": "BuildingSF",
            "fields": {
                "restaurant": {
                    "name": "restaurant",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "warehouse": {
                    "name": "warehouse",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "brewery": {
                    "name": "brewery",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "multifamily": {
                    "name": "multifamily",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "officeAndRetail": {
                    "name": "officeAndRetail",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "medical": {
                    "name": "medical",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "uniqueLayouts": {
                    "name": "uniqueLayouts",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "UserDetails": {
            "name": "UserDetails",
            "fields": {
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "email": {
                    "name": "email",
                    "isArray": false,
                    "type": "AWSEmail",
                    "isRequired": false,
                    "attributes": []
                },
                "phoneNumber": {
                    "name": "phoneNumber",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "PromoCodeReference": {
            "name": "PromoCodeReference",
            "fields": {
                "code": {
                    "name": "code",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "requirements": {
                    "name": "requirements",
                    "isArray": false,
                    "type": {
                        "nonModel": "PromoRequirements"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "promoClass": {
                    "name": "promoClass",
                    "isArray": false,
                    "type": {
                        "enum": "PromoClass"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "discount": {
                    "name": "discount",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "disablePromoCode": {
                    "name": "disablePromoCode",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "expirationDate": {
                    "name": "expirationDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "applyExpirationDate": {
                    "name": "applyExpirationDate",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "uses": {
                    "name": "uses",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "PromoRequirements": {
            "name": "PromoRequirements",
            "fields": {
                "requireSignin": {
                    "name": "requireSignin",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "minimumSubtotal": {
                    "name": "minimumSubtotal",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "minimumArea": {
                    "name": "minimumArea",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "DisciplineBasePrice": {
            "name": "DisciplineBasePrice",
            "fields": {
                "discipline": {
                    "name": "discipline",
                    "isArray": false,
                    "type": {
                        "enum": "TradeSupervision"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "setUpFee": {
                    "name": "setUpFee",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "costPerSF": {
                    "name": "costPerSF",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "DisciplinePricing": {
            "name": "DisciplinePricing",
            "fields": {
                "mech": {
                    "name": "mech",
                    "isArray": false,
                    "type": {
                        "nonModel": "DisciplineBasePrice"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "elec": {
                    "name": "elec",
                    "isArray": false,
                    "type": {
                        "nonModel": "DisciplineBasePrice"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "plum": {
                    "name": "plum",
                    "isArray": false,
                    "type": {
                        "nonModel": "DisciplineBasePrice"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "gas": {
                    "name": "gas",
                    "isArray": false,
                    "type": {
                        "nonModel": "DisciplineBasePrice"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            }
        }
    },
    "codegenVersion": "3.3.6",
    "version": "1653ed8f67267272fc6ca7982492a5c5"
};