import React from "react";
import { Loader, useTheme } from "@aws-amplify/ui-react";

const LoaderComponent = () => {
  const { tokens } = useTheme();
  const logo = require("../Assets/permitZIP.png");
  return (
    <div style={styles.loaderContainer(tokens)}>
      {logo && <img src={logo} alt="Loading..." style={styles.logo} />}
      <Loader
        margin={"20px 0"}
        size="small"
        variation="linear"
        width={"150px"}
        color={tokens.colors.blue[500]}
      />
    </div>
  );
};

const styles = {
  loaderContainer: (tokens) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100vw",
    backgroundColor: tokens.colors.background.primary,
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 9999,
  }),
  logo: {
    marginTop: "10px",
    width: "120px",
    height: "auto",
    backgroundColor: "#fff",
    borderRadius: "50%",
    minWidth: "70px",
    minHeight: "70px",
    flexShrink: 0,
    padding: "2px",
    border: `1px solid #c5c5c5`,
  },
};

export default LoaderComponent;
