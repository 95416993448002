import React from "react";
import {
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
} from "@aws-amplify/ui-react";

const DynamicPricingTable = ({
  getMechTotal,
  getElecTotal,
  getPlumTotal,
  selectedTrades,
  selectedSetUps,
  projectSetUpFee,
  setUpFee,
  areas,
  formatNumberUS,
}) => {
  const data = {
    disciplines: ["Mech", "Elec", "Plum"],
    rows: {
      Total: {
        Total: getMechTotal() + getElecTotal() + getPlumTotal(),
        Mech: getMechTotal(),
        Elec: getElecTotal(),
        Plum: getPlumTotal(),
      },
      ProjectSetup: {
        Total: projectSetUpFee?.total ? projectSetUpFee?.total : 0,
        Mech: projectSetUpFee?.mech ? projectSetUpFee?.mech : 0,
        Elec: projectSetUpFee?.elec ? projectSetUpFee?.elec : 0,
        Plum: projectSetUpFee?.plum ? projectSetUpFee?.plum : 0,
      },
      SpaceSetup: {
        Total: setUpFee?.total[0] ? setUpFee?.total[0] : 0,
        Mech: setUpFee?.mech[0] ? setUpFee?.mech[0] : 0,
        Elec: setUpFee?.elec[0] ? setUpFee?.elec[0] : 0,
        Plum: setUpFee?.plum[0] ? setUpFee?.plum[0] : 0,
      },
      Areas: areas,
    },
  };

  // Extract columns from disciplines and added required columns fields
  const columns = ["firstCell", "Total", ...data.disciplines]; // firstCell denotes the empty cell in the first column

  // Build rows array
  const rows = [
    { name: "Total", ...data.rows.Total },
    { name: "Project Setup", ...data.rows.ProjectSetup },
    { name: "Space Setup", ...data.rows.SpaceSetup },
    ...data.rows.Areas.map((area) => ({ name: area.Name, ...area })),
  ];

  return (
    <>
      <Table variation="bordered" size="small">
        <TableHead>
          <TableRow>
            {columns.map((col, index) => {
              if (
                selectedTrades.includes(col) ||
                col === "Total" ||
                col === "firstCell"
              ) {
                return (
                  <TableCell
                    as="th"
                    key={index}
                    fontSize={"100%"}
                    border={col === "firstCell" && "none"}
                  >
                    {col === "firstCell" ? "" : col}
                  </TableCell>
                );
              }
            })}
          </TableRow>
        </TableHead>

        <TableBody>
          {rows.map((row, index) => {
            if (
              (row.name !== "Project Setup" && row.name !== "Space Setup") ||
              selectedSetUps.includes(row.name)
            ) {
              return (
                <TableRow key={index}>
                  {columns.map((col, colIndex) =>
                    col === "firstCell" ? (
                      <TableCell
                        as="th"
                        key={colIndex}
                        fontSize={"100%"}
                        minWidth={"80px"}
                      >
                        {row.name}
                      </TableCell>
                    ) : (
                      (selectedTrades.includes(col) || col === "Total") && (
                        <TableCell
                          backgroundColor={
                            (row.name === "Total" || col === "Total") &&
                            "#1d2021"
                          }
                          fontStyle={
                            (row.name === "Total" || col === "Total") &&
                            "italic"
                          }
                          fontSize={
                            row.name === "Total" || col === "Total"
                              ? "120%"
                              : "115%"
                          }
                          fontWeight={
                            row.name === "Total" || col === "Total"
                              ? "500"
                              : "400"
                          }
                          minWidth={"85px"}
                          key={colIndex}
                        >
                          ${formatNumberUS(row[col])}
                        </TableCell>
                      )
                    )
                  )}
                </TableRow>
              );
            }
          })}
        </TableBody>
      </Table>
    </>
  );
};

export default DynamicPricingTable;
