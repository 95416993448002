import {
  Flex,
  Text,
  Heading,
  Card,
  Image,
  TextField,
  Button,
  SelectField,
  TextAreaField,
} from "@aws-amplify/ui-react";
import { useContext, useState, useEffect } from "react";
import { PzPrimeContext } from "../Context";
import { DataStore } from "aws-amplify";
import { CostFlagTemplate } from "../models";
import ModalComponent from "./ModalComponent";

const CostFlagTemplateForm = () => {
  const { colorMode } = useContext(PzPrimeContext);

  const [isCostFlagFormVisible, setIsCostFlagFormVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [flagName, setFlagName] = useState("");
  const [flagDescription, setFlagDescription] = useState("");
  const [alertLevel, setAlertLevel] = useState("SUCCESS");
  const [costFlags, setCostFlags] = useState([]);
  const [costFlagId, setCostFlagId] = useState();

  const customCss = `
      .zIndex{
      overflow-wrap: break-word;
      }
      .outline{
        outline: 1px solid rgba(191, 191, 191, 0.80);
      }
      .iconHover:hover{
        background:lightblue;
      }
      .hover:hover{
        -webkit-box-shadow: 3px -3px 17px 1px rgba(174,179,183,1);
        -moz-box-shadow: 3px -3px 17px 1px rgba(174,179,183,1);
        box-shadow: 3px -3px 17px 1px rgba(174,179,183,1);
        transition: 0.1s ease-in;
      }
      .addSpaceBox {
        background-image: repeating-linear-gradient(-47deg, #aeb3b7, #aeb3b7 16px, transparent 16px, transparent 28px, #aeb3b7 28px), repeating-linear-gradient(43deg, #aeb3b7, #aeb3b7 16px, transparent 16px, transparent 28px, #aeb3b7 28px), repeating-linear-gradient(133deg, #aeb3b7, #aeb3b7 16px, transparent 16px, transparent 28px, #aeb3b7 28px), repeating-linear-gradient(223deg, #aeb3b7, #aeb3b7 16px, transparent 16px, transparent 28px, #aeb3b7 28px);
        background-size: 2px calc(100% + 41.06px), calc(100% + 41.06px) 2px, 2px calc(100% + 41.06px) , calc(100% + 41.06px) 2px;
        background-position: 0 0, 0 0, 100% 0, 0 100%;
        background-repeat: no-repeat;
        animation: borderAnimation 2s infinite linear;
        border-radius:10px;
        }
        .costFlagScroll{
          z-index:99;
        }
      `;
  const darkCss = `
      .amplify-select {
        background:#191f21;
      }`;

  async function saveTemplate() {
    if (!nameError) {
      if (isEdit) {
        const costFlag = await DataStore.query(CostFlagTemplate, costFlagId);
        await DataStore.save(
          CostFlagTemplate.copyOf(costFlag, (costFlagCopy) => {
            costFlagCopy.alertBody = flagDescription;
            costFlagCopy.alertLevel = alertLevel;
            costFlagCopy.alertTitle = flagName;
            costFlagCopy.featureTitle = flagName;
          })
        )
          .then((res) => console.log(res))
          .catch((err) => console.log(err));
        setIsEdit(false);
      } else
        await DataStore.save(
          new CostFlagTemplate({
            featureTitle: flagName,
            alertLevel: alertLevel,
            alertTitle: flagName,
            alertBody: flagDescription,
          })
        )
          .then((res) => console.log(res))
          .catch((err) => console.log(err));
    }
  }

  function editFlag(costFlag) {
    setFlagName(costFlag.featureTitle);
    setFlagDescription(costFlag.alertBody);
    setAlertLevel(costFlag.alertLevel);
    setIsCostFlagFormVisible(true);
    setIsEdit(true);
    setCostFlagId(costFlag.id);
  }

  function addCostFlag() {
    setFlagName("");
    setFlagDescription("");
    setAlertLevel("SUCCESS");
    setIsCostFlagFormVisible(true);
    setCostFlagId("");
  }

  useEffect(() => {
    DataStore.observeQuery(CostFlagTemplate).subscribe(({ items }) =>
      setCostFlags(items)
    );
  }, []);

  async function changeInName(e) {
    setFlagName(e.target.value);
    let costFlags = [];
    if (costFlagId)
      costFlags = await DataStore.query(CostFlagTemplate, (flag) =>
        flag.and((flag) => [
          flag.featureTitle.eq(e.target.value),
          flag.id.ne(costFlagId),
        ])
      );
    else
      costFlags = await DataStore.query(CostFlagTemplate, (flag) =>
        flag.featureTitle.eq(e.target.value)
      );
    setNameError(costFlags.length > 0);
  }

  return (
    <>
      <style>{colorMode === "dark" ? customCss + darkCss : customCss}</style>
      <Flex
        className="costFlagScroll"
        width={"100vw"}
        padding={"30px"}
        wrap={"wrap"}
        alignItems="center"
        overflow={'auto'}
        height={'73vh'}
      >
        <Flex
          direction={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          className="cursor hover"
          padding={"10px"}
          width={"90%"}
          minHeight={"250px"}
          position={"relative"}
          onClick={() => addCostFlag()}
          border={"1px solid #c5c5c5"}
          borderRadius={"10px"}
          margin={"10px"}
        >
          <Image
            className="cursor pointerEvents"
            alt="Add Cost flags"
            src={require("../Assets/addBtn.png")}
            height="120px"
          />
          <Heading
            level={2}
            paddingLeft={"5px"}
            margin={"10px"}
            color={"#aeb3b7"}
            textAlign={"center"}
          >
            Add a Cost Flag
          </Heading>
        </Flex>
        {costFlags.map((costFlag, index) => (
          <Flex
            key={index}
            direction={"column"}
            justifyContent={"center"}
            alignItems={"flex-start"}
            className="cursor hover"
            padding={"10px"}
            width={"90%"}
            position={"relative"}
            onClick={() => editFlag(costFlag)}
            border={"1px solid #c5c5c5"}
            borderRadius={"10px"}
            margin={"10px"}
          >
            <Flex
              alignItems={"stretch"}
              justifyContent={"space-between"}
              width={"100%"}
            >
              <Heading
                level={5}
                paddingLeft={"5px"}
                margin={"5px"}
                textAlign={"center"}
              >
                {costFlag.featureTitle}
              </Heading>
              <Heading paddingRight={"10px"} marginTop={"5px"}>
                {costFlag.alertLevel}
              </Heading>
            </Flex>
            <Text paddingLeft={"10px"} marginBottom={"5px"}>
              {costFlag.alertBody}
            </Text>
          </Flex>
        ))}
      </Flex>

      {isCostFlagFormVisible && (
        <ModalComponent
          showModal={isCostFlagFormVisible}
          onClose={() => setIsCostFlagFormVisible(false)}
          children={
            <Card
              display={"flex"}
              direction={"column"}
              gap={"15px"}
              padding={"10px"}
            >
              <Heading>
                <Text level={4}>Cost Flags</Text>
              </Heading>

              <TextField
                label="Flag Name"
                size="default"
                value={flagName}
                onChange={(e) => changeInName(e)}
                hasError={nameError}
                errorMessage="Template name already exist"
              />

              <TextAreaField
                label="Flag Description"
                resize="vertical"
                rows={3}
                className="zIndex"
                value={flagDescription}
                onChange={(e) => setFlagDescription(e.target.value)}
              />
              <SelectField
                label="Flag Level"
                onChange={(e) => setAlertLevel(e.target.value)}
                value={alertLevel}
              >
                <option value="SUCCESS">SUCCESS</option>
                <option value="WARNING">WARNING</option>
                <option value="ERROR">ERROR</option>
              </SelectField>

              <Button
                width={"100%"}
                marginTop={"15px"}
                maxWidth={"600px"}
                fontSize="18px"
                color={"#fff"}
                fontWeight="600"
                backgroundColor={"brand.primary.80"}
                onClick={() => {
                  saveTemplate();
                  setIsCostFlagFormVisible(false);
                }}
              >
                Save Flag
              </Button>
            </Card>
          }
        />
      )}
    </>
  );
};

export default CostFlagTemplateForm;
