import { Flex, Text, CheckboxField, View } from "@aws-amplify/ui-react";
import { useContext, useEffect, useState } from "react";
import { PzPrimeContext } from "../Context/ProposalContext";
import { PzTooltip } from ".";

const TradeSupervisionSelector = ({
  isInstantQuoteForm,
  setChangeInInputValues,
  changeInInputValues,
  isTradeSelected,
  setIsTradeSelected,
  isRequirementMet,
  setIsWarningVisible,
  setQuoteRequirements,
}) => {
  const {
    discipline,
    setDiscipline,
    colorMode,
    changeInInput,
    setChangeInInput,
    openMissingFields,
  } = useContext(PzPrimeContext);

  const [changeInTrade, setChangeInTrade] = useState(false);

  useEffect(() => {}, [changeInTrade]);

  const customCss = `
    .cursor{
        cursor:pointer;
    }
    .checkBox{
        width:100%;
        justify-content: space-between;
    }
    label.amplify-flex.amplify-checkbox {
        width: 100%;
        justify-content: space-between;
        border: 1px solid #AEB3B7;
        border-radius: 10px;
        padding:10px;
    }
    `;

  function changeInDiscipline(e) {
    discipline[e.target.name] = e.target.checked ? 1 : 0;
    setDiscipline(discipline);
    setChangeInTrade(!changeInTrade);
    if (isInstantQuoteForm) {
      setChangeInInputValues(!changeInInputValues);
      setQuoteRequirements("");
    } else {
      if (!openMissingFields) {
        setChangeInInput(!changeInInput);
      }
      setIsWarningVisible(false);
    }
    setIsTradeSelected(true);
  }

  return (
    <Flex
      width={"100%"}
      direction={"column"}
      padding={"15px"}
      // backgroundColor={colorMode === "dark" && "#2e3436"}
      borderRadius={"10px"}
      // border={colorMode === "dark" ? "none" : "1px solid #c5c5c5"}
    >
      <style>{customCss}</style>
      <Flex justifyContent={"space-between"} width={"100%"}>
        <Text fontSize={"22px"}>Engineering services to include</Text>
        <View
          display={{
            base: "none",
            small: "block",
          }}
        >
          <PzTooltip
            place={"top"}
            isIcon={true}
            text="Select the engineering disciplines necessary for your project"
          />
        </View>
      </Flex>
      {(isInstantQuoteForm || !isRequirementMet) && !isTradeSelected && (
        <Text
          fontSize={"16px"}
          marginLeft={"5px"}
          marginBottom={"0px"}
          color={colorMode === "dark" ? "#f5bcbc" : "#660000"}
        >
          *Select at least one engineering discipline for a valid estimate
        </Text>
      )}
      <Flex
        padding="20px"
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        gap="10px"
        align-self="stretch"
        width={"100%"}
        border={
          (isInstantQuoteForm || !isRequirementMet) && !isTradeSelected
            ? colorMode === "dark"
              ? "1px solid #f5bcbc"
              : "1px solid #660000"
            : "1px solid #AEB3B7"
        }
        borderRadius={"10px"}
      >
        <CheckboxField
          className="checkBox"
          label="Mechanical"
          name="mechanical"
          value="yes"
          labelPosition="start"
          checked={discipline.mechanical}
          onChange={(e) => changeInDiscipline(e)}
        />
        <CheckboxField
          className="checkBox"
          label="Electrical"
          name="electrical"
          value="yes"
          labelPosition="start"
          checked={discipline.electrical}
          onChange={(e) => changeInDiscipline(e)}
        />
        <CheckboxField
          className="checkBox"
          label="Plumbing"
          name="plumbing"
          value="yes"
          labelPosition="start"
          checked={discipline.plumbing}
          onChange={(e) => changeInDiscipline(e)}
        />
      </Flex>
    </Flex>
  );
};

export default TradeSupervisionSelector;
