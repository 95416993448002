const itemPriceRounding = 25;

const calculation = {
  electrical: 0,
  plumbing: 0,
  mechanical: 0,
  baseFee: 0,
  subTotal: 0,
};

const packages = {
  gist: 0,
  minimalist: 0,
  collaborator: 0,
  perfectionist: 0,
  twoWeek: 0,
};

const contractPricingConfigurations = {
  projectSetupFee: 600,
  kitchenSetupFee: 750,
  brewerySetupFee: 300,

  percentProgressAtSchematicDesign: 0.2,
  percentProgressAtDesignDevelopment: 0.4,
  percentProgressAtConstructionDocuments: 0.4,
  percentForSchematicDesignIteration: 0.15,
  percentForDesignDevelopmentIteration: 0.3,

  minimumAdditionalServicesHoursPerTradeByPercent: 0.3,
  minimumAdditionalServicesHoursPerTrade: 2,
  percentageOfContingencyHoursExpedited: 0.1,

  pricePerUniqueMultiFamilyLayout: 750,

  adaptiveReusePremiumCleanSlate: 0.2,
  adaptiveReusePremiumRecycledSystem: 0.6,

  additionalServicesBillableRate: 250,
  expeditedRate: 500,

  inPersonSurveyFee: 950,
};

const deliverableQuantity = {
  gist: {
    schematicDesign: 1,
    preProductionMiniTasks: 0,
    constructionDocument: 0,
    designDevelopment: 0,
    inProductionRedesign: 0,
    planReviewComments: false,
    submittalReview: false,
  },
  minimalist: {
    schematicDesign: 1,
    preProductionMiniTasks: 1,
    constructionDocument: 1,
    designDevelopment: 1,
    inProductionRedesign: 0,
    planReviewComments: true,
    submittalReview: true,
  },
  collaborator: {
    schematicDesign: 1,
    preProductionMiniTasks: 4,
    constructionDocument: 1,
    designDevelopment: 1,
    inProductionRedesign: 1,
    planReviewComments: true,
    submittalReview: true,
  },
  perfectionist: {
    schematicDesign: 1,
    preProductionMiniTasks: 6,
    constructionDocument: 1,
    designDevelopment: 1,
    inProductionRedesign: 3,
    planReviewComments: true,
    submittalReview: true,
  },
};

const disciplinePricingConfigurations = [
  {
    discipline: "electrical",
    setupFees: 500,
    pricePerSquareFootBase: 0.28,
    baseSquareFootageMultiplier: [
      {
        category: "restaurant",
        multiplier: 1.5,
      },
      {
        category: "brewery",
        multiplier: 1.25,
      },
      {
        category: "office",
        multiplier: 1,
      },
      {
        category: "medical",
        multiplier: 4,
      },
      {
        category: "multiFamily",
        multiplier: [0.1181, 0.17629],
      },
      {
        category: "warehouse",
        multiplier: [0.04, 0.075],
      },
    ],
    specialtySetupFees: [
      {
        category: "restaurant",
        multiplier: 2500,
      },
      {
        category: "brewery",
        multiplier: 1000,
      },
    ],
  },
  {
    discipline: "plumbing",
    setupFees: 500,
    pricePerSquareFootBase: 0.28,
    baseSquareFootageMultiplier: [
      {
        category: "restaurant",
        multiplier: 1.5,
      },
      {
        category: "brewery",
        multiplier: 1.25,
      },
      {
        category: "office",
        multiplier: 0.5,
      },
      {
        category: "medical",
        multiplier: 4,
      },
      {
        category: "multiFamily",
        multiplier: [0.14, 0.2074],
      },
      {
        category: "warehouse",
        multiplier: [0.04, 0.075],
      },
    ],
    specialtySetupFees: [
      {
        category: "restaurant",
        multiplier: 2500,
      },
      {
        category: "brewery",
        multiplier: 1000,
      },
    ],
  },
  {
    discipline: "mechanical",
    setupFees: 750,
    pricePerSquareFootBase: 0.3,
    baseSquareFootageMultiplier: [
      {
        category: "restaurant",
        multiplier: 1.5,
      },
      {
        category: "brewery",
        multiplier: 1.25,
      },
      {
        category: "office",
        multiplier: 1,
      },
      {
        category: "medical",
        multiplier: 4,
      },
      {
        category: "multiFamily",
        multiplier: [0.1181, 0.17629],
      },
      {
        category: "warehouse",
        multiplier: [0.075, 0.095],
      },
    ],
    specialtySetupFees: [
      {
        category: "restaurant",
        multiplier: 750,
      },
      {
        category: "brewery",
        multiplier: 250,
      },
    ],
  },
];

const multiFamilyMultiplier = (a, b, buildingSquareFootage) => {
  const c = -buildingSquareFootage["multiFamily"] * 0.0001;
  return a * Math.exp(c) + b;
};

// For calculating warehouse per square feet
const warehouseMultiplier = (a, b, buildingSquareFootage) => {
  const c = -buildingSquareFootage["warehouse"] * 0.000008;
  return a * Math.exp(c) + b;
};

// For calculating global project setup
const calculateGlobalProjectSetup = (buildingSquareFootage) => {
  calculation["baseFee"] = contractPricingConfigurations["projectSetupFee"];
  if (buildingSquareFootage["restaurant"] > 0)
    calculation["baseFee"] += contractPricingConfigurations["kitchenSetupFee"];
  if (buildingSquareFootage["brewery"] > 0)
    calculation["baseFee"] += contractPricingConfigurations["brewerySetupFee"];
  return calculation["baseFee"];
};

function getDiscipline(arrObj, value) {
  return arrObj.filter((obj) => {
    return obj.discipline === value || obj.category === value;
  })[0];
}

// For calculating subTotal
const calculateBaseSubTotal = (discipline, buildingSquareFootage, constructionType) => {
  let totalNumberOfIncludedDiscipline = 0;
  for (const trade in discipline) {
    if (discipline[trade] === 1) {
      totalNumberOfIncludedDiscipline++;
      const dis = getDiscipline(disciplinePricingConfigurations, trade);
      calculation[trade] =
        dis["setupFees"] +
        dis["pricePerSquareFootBase"] *
          (buildingSquareFootage["restaurant"] *
            getDiscipline(dis["baseSquareFootageMultiplier"], "restaurant")[
              "multiplier"
            ] +
            buildingSquareFootage["brewery"] *
              getDiscipline(dis["baseSquareFootageMultiplier"], "brewery")[
                "multiplier"
              ] +
            buildingSquareFootage["medical"] *
              getDiscipline(dis["baseSquareFootageMultiplier"], "medical")[
                "multiplier"
              ] +
            buildingSquareFootage["office"] *
              getDiscipline(dis["baseSquareFootageMultiplier"], "office")[
                "multiplier"
              ]);
      if (buildingSquareFootage["restaurant"] > 0)
        calculation[trade] += getDiscipline(
          dis["specialtySetupFees"],
          "restaurant"
        )["multiplier"];
      if (buildingSquareFootage["brewery"] > 0)
        calculation[trade] += getDiscipline(
          dis["specialtySetupFees"],
          "brewery"
        )["multiplier"];
      const multiFam = getDiscipline(
        dis["baseSquareFootageMultiplier"],
        "multiFamily"
      )["multiplier"];
      calculation[trade] +=
        multiFamilyMultiplier(multiFam[0], multiFam[1], buildingSquareFootage) *
        buildingSquareFootage["multiFamily"];
      const warehouse = getDiscipline(
        dis["baseSquareFootageMultiplier"],
        "warehouse"
      )["multiplier"];
      calculation[trade] +=
        warehouseMultiplier(warehouse[0], warehouse[1], buildingSquareFootage) *
        buildingSquareFootage["warehouse"];
      calculation[trade] = Math.round(calculation[trade]);
    }
  }
  let multiFamLayoutSetUpFee = 0;
  if (buildingSquareFootage["multiFamily"] > 0)
    multiFamLayoutSetUpFee =
      totalNumberOfIncludedDiscipline *
      contractPricingConfigurations["pricePerUniqueMultiFamilyLayout"] *
      buildingSquareFootage["uniqueLayouts"];
  calculation["subTotal"] =
    calculation["electrical"] +
    calculation["mechanical"] +
    calculation["plumbing"] +
    multiFamLayoutSetUpFee;
  calculation["subTotal"] += calculateGlobalProjectSetup(buildingSquareFootage);
  if (constructionType === "Adaptive Reuse/Clean Slate")
    calculation["subTotal"] *=
      1 + contractPricingConfigurations["adaptiveReusePremiumCleanSlate"];
  else if (constructionType === "Adaptive Reuse/Recycled System")
    calculation["subTotal"] *=
      1 + contractPricingConfigurations["adaptiveReusePremiumRecycledSystem"];
  calculation["subTotal"] = Math.round(calculation["subTotal"]);
};

const deliverableItemPrice = {
  schematicDesign: 0,
  preProductionMiniTasks: 0,
  constructionDocument: 0,
  designDevelopment: 0,
  inProductionRedesign: 0,
  preliminaryFieldVisit: 0,
  meetings: 0,
  additionalServicesHours:
    contractPricingConfigurations["additionalServicesBillableRate"],
};

const roundOff = (value, roundOffValue) => {
  if (Math.round(value) % roundOffValue !== 0) {
    value =
      Math.round(value) + (roundOffValue - (Math.round(value) % roundOffValue));
  }
  return Math.round(value);
};

const calculateTotalContingencyHours = (discipline, buildingSquareFootage) => {
  let totalContingencyHours = 0;
  for (const trade in discipline) {
    var contingencyHours = 0;
    if (discipline[trade] === 1) {
      if (buildingSquareFootage["multiFamily"] > 0)
        contingencyHours = Math.ceil(
          Math.max(
            ((buildingSquareFootage["uniqueLayouts"] *
              contractPricingConfigurations["pricePerUniqueMultiFamilyLayout"] +
              calculation[trade]) *
              contractPricingConfigurations[
                "minimumAdditionalServicesHoursPerTradeByPercent"
              ]) /
              contractPricingConfigurations["additionalServicesBillableRate"],
            contractPricingConfigurations[
              "minimumAdditionalServicesHoursPerTrade"
            ]
          )
        );
      else
        contingencyHours = Math.ceil(
          Math.max(
            (calculation[trade] *
              contractPricingConfigurations[
                "minimumAdditionalServicesHoursPerTradeByPercent"
              ]) /
              contractPricingConfigurations["additionalServicesBillableRate"],
            contractPricingConfigurations[
              "minimumAdditionalServicesHoursPerTrade"
            ]
          )
        );
    }
    totalContingencyHours += contingencyHours;
  }
  return totalContingencyHours;
};

// For calculating total for each package
// Calculation for Additional In-Person Surveys and Additional meetings (or virtual surveys) are not done
const packagePriceTotal = (discipline, buildingSquareFootage, constructionType) => {
  calculateBaseSubTotal(discipline, buildingSquareFootage, constructionType);
  for (const pkg in packages) {
    if (pkg === "twoWeek") continue;
    deliverableItemPrice["schematicDesign"] = roundOff(
      calculation["subTotal"] *
        contractPricingConfigurations["percentProgressAtSchematicDesign"],
      itemPriceRounding
    );
    const schematicDesignPriceTotal =
      deliverableItemPrice["schematicDesign"] *
      deliverableQuantity[pkg]["schematicDesign"];
    deliverableItemPrice["designDevelopment"] = roundOff(
      calculation["subTotal"] *
        contractPricingConfigurations["percentProgressAtDesignDevelopment"],
      itemPriceRounding
    );
    const designDevelopmentPriceTotal =
      deliverableItemPrice["designDevelopment"] *
      deliverableQuantity[pkg]["designDevelopment"];
    deliverableItemPrice["constructionDocument"] = roundOff(
      calculation["subTotal"] *
        contractPricingConfigurations["percentProgressAtConstructionDocuments"],
      itemPriceRounding
    );
    const constructionDocumentsPriceTotal =
      deliverableItemPrice["constructionDocument"] *
      deliverableQuantity[pkg]["constructionDocument"];
    // contractPricingConfigurations sub total for each package
    const baseSubTotal =
      schematicDesignPriceTotal +
      designDevelopmentPriceTotal +
      constructionDocumentsPriceTotal;
    deliverableQuantity[pkg]["preliminaryFieldVisit"] =
      constructionType === "Adaptive Reuse/Recycled System" ||
      constructionType === "Adaptive Reuse/Clean Slate"
        ? 1
        : 0;
    // The below commented code is for virtual survey
    // if (surveyType["isVirtual"] === 1)
    //   deliverableItemPrice["preliminaryFieldVisit"] = roundOff(
    //     (discipline["electrical"] +
    //       discipline["mechanical"] +
    //       discipline["plumbing"]) *
    //       contractPricingConfigurations["additionalServicesBillableRate"],
    //     itemPriceRounding
    //   );
    // else
    deliverableItemPrice["preliminaryFieldVisit"] = roundOff(
      contractPricingConfigurations["inPersonSurveyFee"],
      itemPriceRounding
    );
    const preliminaryFieldVisit =
      deliverableQuantity[pkg]["preliminaryFieldVisit"] *
      deliverableItemPrice["preliminaryFieldVisit"];
    deliverableItemPrice["preProductionMiniTasks"] = roundOff(
      baseSubTotal *
        contractPricingConfigurations["percentForSchematicDesignIteration"],
      itemPriceRounding
    );
    if (pkg === "gist") deliverableItemPrice["preProductionMiniTasks"] /= 0.4; //Currently roundoff not applied
    const preProductionMiniTasks =
      deliverableItemPrice["preProductionMiniTasks"] *
      deliverableQuantity[pkg]["preProductionMiniTasks"];
    deliverableItemPrice["inProductionRedesign"] = roundOff(
      baseSubTotal *
        contractPricingConfigurations["percentForDesignDevelopmentIteration"],
      itemPriceRounding
    );
    const inProductionRedesign =
      deliverableItemPrice["inProductionRedesign"] *
      deliverableQuantity[pkg]["inProductionRedesign"];
    deliverableItemPrice["meetings"] = roundOff(
      (discipline["electrical"] +
        discipline["mechanical"] +
        discipline["plumbing"]) *
        contractPricingConfigurations["additionalServicesBillableRate"],
      itemPriceRounding
    );
    deliverableQuantity[pkg]["meetings"] =
      deliverableQuantity[pkg]["schematicDesign"] +
      deliverableQuantity[pkg]["preProductionMiniTasks"] +
      deliverableQuantity[pkg]["inProductionRedesign"];
    const meetings =
      deliverableItemPrice["meetings"] * deliverableQuantity[pkg]["meetings"];
    deliverableQuantity[pkg]["additionalServicesHours"] =
      calculateTotalContingencyHours(discipline, buildingSquareFootage);
    if (pkg === "collaborator")
      deliverableQuantity[pkg]["additionalServicesHours"] *= 2;
    if (pkg === "perfectionist")
      deliverableQuantity[pkg]["additionalServicesHours"] *= 4;
    const totalGeneralUseHours =
      deliverableQuantity[pkg]["additionalServicesHours"] *
      roundOff(
        contractPricingConfigurations["additionalServicesBillableRate"],
        itemPriceRounding
      );
    let totalUrgentAdditionalServicesHours = 0;
    if (pkg === "perfectionist")
      totalUrgentAdditionalServicesHours = Math.round(
        deliverableQuantity[pkg]["additionalServicesHours"] *
          contractPricingConfigurations[
            "percentageOfContingencyHoursExpedited"
          ] *
          contractPricingConfigurations["expeditedRate"]
      );
    const supportSubtotal =
      preliminaryFieldVisit +
      preProductionMiniTasks +
      inProductionRedesign +
      meetings +
      totalGeneralUseHours +
      totalUrgentAdditionalServicesHours;
    packages[pkg] = baseSubTotal + supportSubtotal;
    if (packages[pkg] > 30000 && packages[pkg] % 1000 !== 0)
      packages[pkg] = (parseInt(packages[pkg] / 1000) + 1) * 1000;
    else if (packages[pkg] % 100 !== 0)
      packages[pkg] = (parseInt(packages[pkg] / 100) + 1) * 100;
  }
  packages["twoWeek"] = packages["minimalist"] * 1.4;
  if (packages["twoWeek"] > 30000 && packages["twoWeek"] % 1000 !== 0)
    packages["twoWeek"] = (parseInt(packages["twoWeek"] / 1000) + 1) * 1000;
  else if (packages["twoWeek"] % 100 !== 0)
    packages["twoWeek"] = (parseInt(packages["twoWeek"] / 100) + 1) * 100;
  return packages;
};

export default packagePriceTotal;
