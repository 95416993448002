import {
  Flex,
  Text,
  Heading,
  Card,
  Image,
  Button,
  Collection,
  Badge,
} from "@aws-amplify/ui-react";
import { DataStore } from "aws-amplify";
import React, { useEffect, useState, useContext, useRef } from "react";
import { Project } from "../../models";
import ProjectCollection from "../ProjectCollection";

const LoadProjectDataModal = ({
  setProposalData,
  setOpenLoadProjectModal,
  isProjectLink,
  setLinkProject,
  swalWithDefaults,
}) => {
  const [projectItems, setProjectItems] = useState("");
  const [itemCount, setItemCount] = useState(0);
  const [isBottom, setIsBottom] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    DataStore.observeQuery(Project).subscribe(({ items }) => {
      items.sort(function (a, b) {
        return new Date(b.updatedAt) - new Date(a.updatedAt);
      });
      setProjectItems(items);
      if (items.length < 5) {
        setItemCount(items.length);
      } else {
        setItemCount(5);
      }
      console.log(items.length);
    });

    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;

      // Check if the user has scrolled to the bottom
      if (scrollTop + clientHeight >= scrollHeight - 20) {
        setIsBottom(true);
      } else {
        setIsBottom(false);
      }
    };

    if (containerRef.current) {
      containerRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    let loadCount = 5;
    if (isBottom) {
      if (projectItems.length < itemCount + loadCount) {
        setItemCount(projectItems.length);
      } else {
        setItemCount(itemCount + loadCount);
      }
      console.log("Reached the bottom of the container!", itemCount);
    }
  }, [isBottom]);

  function loadProjectData(project) {
    if (project.priceSheet.total) {
      setLinkProject(project);
      setProposalData(project);
      setOpenLoadProjectModal(false);
      swalWithDefaults.fire({
        icon: "success",
        title: "Project Loaded Successfully",
        text: `"${project.name}" loaded into sample data`,
      });
    } else {
      swalWithDefaults.fire({
        icon: "question",
        title: "Project Setup Incomplete",
        text: "Project Setup was not completed. Many fields may be empty. Do you still want to load available data?",
        cancelButtonText: `Cancel`,
        showCancelButton: true,
        confirmButtonText: "Continue",
      }).then((result) => {
        if (result.isConfirmed) {
          setLinkProject(project);
          setProposalData(project);
          setOpenLoadProjectModal(false);
          swalWithDefaults.fire({
            icon: "success",
            title: "Project Loaded Successfully",
            text: `Available data from project "${project.name}" loaded. Review sample sheet to add missing information.`,
          });
        }
      });
    }
  }

  function linkProjectData(project) {
    setLinkProject(project);
    setOpenLoadProjectModal(false);
    swalWithDefaults.fire({
      icon: "success",
      title: "Project Linked Successfully",
      text: `This custom proposal is linked to the project "${project.name}"`,
    });
  }

  const loadProjectCss = `
  .amplify-searchfield{
    width:100%;
    position:sticky;
    top:0px;
    background:#fff !important;
    z-index:99;
  }
  `;

  return (
    <Flex
      direction={"column"}
      overflow={"hidden"}
      alignItems={"center"}
      gap={"20px"}
    >
      <style>{loadProjectCss}</style>
      <Heading level={4}>
        {isProjectLink ? "Link Project Data" : "Load Project Data"}
      </Heading>
      <Flex
        direction={"column"}
        padding={"10px"}
        width={"100%"}
        height={"100%"}
        overflow={"auto"}
        ref={containerRef}
      >
        <ProjectCollection
          projectLoadCount={100000}
          projectList={projectItems}
          loadPage={"proposalEditor"}
          optionalFunction={isProjectLink ? linkProjectData : loadProjectData}
          isProjectLink={isProjectLink}
        />
      </Flex>
    </Flex>
  );
};

export default LoadProjectDataModal;
