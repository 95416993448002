import {
  Badge,
  Card,
  Flex,
  Heading,
  Icon,
  ScrollView,
  Text,
  TextField,
  View,
} from "@aws-amplify/ui-react";

import { useContext, useEffect, useState } from "react";
import { PzPrimeContext } from "../../Context/ProposalContext";
import { DataStore } from "aws-amplify";
import { Space, Project } from "../../models";

const ProjectSummaryFlexItem = (props) => {
  const { ...rest } = props;

  const {
    projectName,
    address,
    selectedMarkets,
    discipline,
    changeInSpace,
    constructionType,
    projectArea,
    setProjectArea,
    colorMode,
    projectId,
    changeInInput,
    setChangeInInput,
    addCommaToPrice,
    formatNumberUS,
    setIsRequirementMet,
    projectPriceSheets,
    setProjectPriceSheets,
    properNaming,
    setConfiguredSpaces,
    projectSpaces,
    setProjectSpaces,
  } = useContext(PzPrimeContext);

  const [spaces, setSpaces] = useState([]);

  const useContextTradeConversion = {
    mechanical: "mech",
    electrical: "elec",
    plumbing: "plum",
  };

  useEffect(() => {
    getSpaces();
  }, [changeInSpace]);

  async function getSpaces() {
    DataStore.observeQuery(Space, (space) =>
      space.and((space) => [
        space.projectSpacesId.eq(projectId),
        space.className.ne("Project Cost Flags"),
      ])
    ).subscribe(({ items }) => {
      setSpaces(items);
      getProjectPriceSheet();
    });
  }

  async function getProjectPriceSheet() {
    DataStore.observeQuery(Project, (project) =>
      project.id.eq(projectId)
    ).subscribe(({ items }) => {
      setProjectPriceSheets(items[0]?.priceSheet);
    });
  }

  let uniqueName = [];

  function totalSF(spaceType) {
    let totalSpace = 0;
    if (projectSpaces) {
      for (let space in projectSpaces) {
        if (projectSpaces[space]["className"] === spaceType) {
          for (let SF of projectSpaces[space]["areas"]) {
            totalSpace += parseInt(SF.area);
          }
        }
      }
    }
    return totalSpace;
  }

  const customCss = `
  .scrollBar::-webkit-scrollbar {
    width: 16px;               /* width of the entire scrollbar */
  background-color: transparent;

  }

  .scrollBar::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  .scrollBar::-webkit-scrollbar-thumb {
    background-color: #b0b3b5;    /* color of the scroll thumb */
    background-clip: content-box;
    border-radius: 20px;       /* roundness of the scroll thumb */
    border: 3px solid transparent;  /* creates padding around scroll thumb */
  }

  input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  }

  input[type=number] {
  -moz-appearance: textfield;
  }

.cursor{
    cursor:pointer;
}
.onhover{
  background:#c5c5c5;
}
.onhover:hover{
  background:lightblue;
  transition:0.6s ease;
}
.inlineEdit .amplify-input{
  padding: 1px;
  width: 72px;
  min-width: 30px;
  box-shadow: none;
}

.outline{
  outline:1px solid #AEB3B7;
}

.invisibleBlockSummary:after {
  content: "";
  display: block;
  height: 30px;
  width: 100%;
}
`;
  const progressWidth = (percentValue) => {
    let totalSpaceAreas = 0;

    for (let space in projectSpaces) {
      for (let singleArea of projectSpaces[space]["areas"]) {
        totalSpaceAreas += singleArea.area;
      }
    }

    let minAreaNeeded = Math.floor((90 * projectArea) / 100);

    if (totalSpaceAreas > projectArea) {
      setIsRequirementMet(false);
    } else if (totalSpaceAreas < minAreaNeeded) {
      setIsRequirementMet(false);
    } else setIsRequirementMet(true);

    let tempProjectArea = projectArea;
    let currentPercentWidth = (totalSpaceAreas / tempProjectArea) * 100;
    setConfiguredSpaces(totalSpaceAreas);

    if (percentValue === "realValue") return Math.round(currentPercentWidth);
    if (percentValue === "width") {
      if (currentPercentWidth <= 100) {
        return Math.round(currentPercentWidth);
      } else return 100;
    } else {
      return totalSpaceAreas;
    }
  };

  const [isEditing, setIsEditing] = useState(false);
  const [tempAreaValue, setTempAreaValue] = useState(projectArea);

  const onAreaChange = (value) => {
    setTempAreaValue(value);
  };

  const onExit = (value) => {
    if (value === "save") {
      setProjectArea(parseInt(tempAreaValue));
      setChangeInInput(!changeInInput);
    } else {
      setProjectArea(parseInt(projectArea));
    }
    setIsEditing(false);
  };

  return (
    <>
      <style>{customCss}</style>
      <Card
        {...rest}
        display={"flex"}
        direction={"column"}
        alignItems={"center"}
        className="outline"
        justifyContent={"flex-start"}
        padding={"15px"}
        height={"100%"}
      >
        <Heading textAlign={"center"} paddingBottom={"15px"}>
          Project Summary
        </Heading>

        <ScrollView
          height={"81vh"}
          className="scrollBar"
          width={"100%"}
          orientation="vertical"
          gap="10px"
          display={"flex"}
          direction="column"
        >
          <Card
            display={"flex"}
            gap="10px"
            direction={"column"}
            borderRadius="5px"
            border="1px solid #AEB3B7"
            backgroundColor={colorMode === "dark" && "#2e3436"}
            width={"100%"}
          >
            <Text
              textAlign={"center"}
              marginBottom={"5px"}
              fontWeight={"500"}
              fontSize={"20px"}
            >
              {projectName}
            </Text>
            <Text>
              <Icon margin={"0px 5px 6px 0px"}>
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="#c5c5c5"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 11.3254C9 12.121 9.31607 12.8841 9.87868 13.4467C10.4413 14.0093 11.2044 14.3254 12 14.3254C12.7956 14.3254 13.5587 14.0093 14.1213 13.4467C14.6839 12.8841 15 12.121 15 11.3254C15 10.5297 14.6839 9.76668 14.1213 9.20407C13.5587 8.64146 12.7956 8.32539 12 8.32539C11.2044 8.32539 10.4413 8.64146 9.87868 9.20407C9.31607 9.76668 9 10.5297 9 11.3254Z"
                    stroke="#354052"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M17.657 16.9824L13.414 21.2254C13.039 21.6 12.5306 21.8105 12.0005 21.8105C11.4704 21.8105 10.962 21.6 10.587 21.2254L6.343 16.9824C5.22422 15.8636 4.46234 14.4381 4.15369 12.8863C3.84504 11.3344 4.00349 9.72591 4.60901 8.26412C5.21452 6.80234 6.2399 5.55293 7.55548 4.6739C8.87107 3.79486 10.4178 3.32568 12 3.32568C13.5822 3.32568 15.1289 3.79486 16.4445 4.6739C17.7601 5.55293 18.7855 6.80234 19.391 8.26412C19.9965 9.72591 20.155 11.3344 19.8463 12.8863C19.5377 14.4381 18.7758 15.8636 17.657 16.9824Z"
                    stroke="#c5c5c5"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Icon>
              {address}
            </Text>

            <Flex wrap={"wrap"} gap={"8px"}>
              {Object.keys(discipline).map((department, index) => {
                if (discipline[department] === 1)
                  return <Badge key={index}>{properNaming(department)}</Badge>;
              })}
            </Flex>
            <Text>Construction Type:</Text>
            <Badge width={"fit-content"}>{constructionType}</Badge>

            <Flex
              width={"100%"}
              alignItems={"center"}
              justifyContent={"center"}
              margin={"5px 0"}
              direction={"column"}
              gap={"10px"}
            >
              <Text fontSize={"18px"}>Total Costs</Text>
              <Text fontWeight={500} fontSize={"17px"}>
                ${" "}
                {projectPriceSheets
                  ? projectPriceSheets["total"]
                    ? formatNumberUS(projectPriceSheets["total"])
                    : "0"
                  : "0"}
              </Text>
              <Flex
                width={"100%"}
                direction={"row"}
                alignItems={"center"}
                justifyContent={"center"}
                margin={"5px 0"}
              >
                <Flex
                  width={"100%"}
                  alignItems={"flex-start"}
                  justifyContent={"center"}
                  gap={"20px"}
                  direction={"column"}
                  paddingLeft={"30px"}
                >
                  {Object.keys(discipline).map((department, index) => {
                    if (discipline[department] === 1)
                      return (
                        <Text key={index} fontSize={"18px"}>
                          {department.charAt(0).toUpperCase() +
                            department.slice(1)}
                        </Text>
                      );
                  })}
                </Flex>
                <Flex
                  width={"100%"}
                  alignItems={"flex-end"}
                  justifyContent={"center"}
                  gap={"20px"}
                  direction={"column"}
                  paddingRight={"40px"}
                >
                  {Object.keys(discipline).map((department, index) => {
                    if (discipline[department] === 1)
                      return (
                        <Text
                          key={index}
                          fontWeight={500}
                          fontSize={"17px"}
                          className="Mechanical"
                          textAlign={"right"}
                        >
                          {projectPriceSheets
                            ? formatNumberUS(
                                projectPriceSheets[
                                  useContextTradeConversion[department] +
                                    "Total"
                                ]
                              )
                            : "0"}{" "}
                          $
                        </Text>
                      );
                  })}
                </Flex>
              </Flex>
            </Flex>
          </Card>

          {projectArea !== 0 && (
            <Card
              display={"flex"}
              gap="8px"
              direction={"column"}
              borderRadius="5px"
              border="1px solid #AEB3B7"
              justifyContent="flex-start"
              width={"100%"}
              // height={"100%"}
              minHeight={"290px"}
              backgroundColor={colorMode === "dark" && "#2e3436"}
            >
              <Text
                textAlign={"center"}
                marginBottom={"5px"}
                fontWeight={"500"}
                fontSize={"20px"}
              >
                Progress Tracker
              </Text>
              <Flex direction={"column"} margin={"10px 0"}>
                <Flex direction={"row"} justifyContent={"space-between"}>
                  <Text>Approximate Project Area</Text>

                  <View
                    display={"flex"}
                    gap="3px"
                    alignItems="center"
                    className="inlineEdit"
                  >
                    <Icon
                      className="cursor onhover"
                      borderRadius={"50%"}
                      padding={"2px"}
                      display={isEditing ? "none" : "block"}
                      onClick={() => setIsEditing(true)}
                    >
                      <svg
                        width="26"
                        height="26"
                        viewBox="0 0 24 24"
                        fill="#545352"
                        xmlns="http://www.w3.org/2000/svg"
                        className="onhover"
                      >
                        <path d="M3 17.2505V21.0005H6.75L17.81 9.94049L14.06 6.19049L3 17.2505ZM20.71 5.63049L18.37 3.29049C18.2775 3.19779 18.1676 3.12424 18.0466 3.07406C17.9257 3.02388 17.796 2.99805 17.665 2.99805C17.534 2.99805 17.4043 3.02388 17.2834 3.07406C17.1624 3.12424 17.0525 3.19779 16.96 3.29049L15.13 5.12049L18.88 8.87049L20.71 7.04049C20.8027 6.94798 20.8762 6.83809 20.9264 6.71712C20.9766 6.59614 21.0024 6.46646 21.0024 6.33549C21.0024 6.20452 20.9766 6.07484 20.9264 5.95387C20.8762 5.83289 20.8027 5.723 20.71 5.63049Z" />
                      </svg>
                    </Icon>

                    {isEditing ? (
                      <View position={"relative"}>
                        <Icon
                          position={"absolute"}
                          right={"20px"}
                          top={"-17px"}
                          className="cursor onhover"
                          borderRadius={"50%"}
                          padding={"2px"}
                          fontSize={"18px"}
                          textAlign={"center"}
                          onClick={() => onExit()}
                        >
                          <svg
                            width="26"
                            height="26"
                            viewBox="0 0 24 24"
                            fill="red"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" />
                          </svg>
                        </Icon>
                        <Icon
                          className="cursor onhover"
                          position={"absolute"}
                          right={"0px"}
                          top={"-17px"}
                          borderRadius={"50%"}
                          padding={"2px"}
                          fontSize={"18px"}
                          onClick={() => onExit("save")}
                        >
                          <svg
                            width="26"
                            height="26"
                            viewBox="0 0 24 24"
                            fill="green"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M9.00016 16.1698L4.83016 11.9998L3.41016 13.4098L9.00016 18.9998L21.0002 6.99984L19.5902 5.58984L9.00016 16.1698Z" />
                          </svg>
                        </Icon>

                        <TextField
                          placeholder="0"
                          value={tempAreaValue}
                          labelHidden
                          onFocus={(e) => e.target.select()}
                          type="number"
                          maxWidth={"70px"}
                          onChange={(e) => onAreaChange(e.target.value)}
                        />
                      </View>
                    ) : (
                      <Text
                        minWidth={"50px"}
                        width={"max-content"}
                        textAlign={"right"}
                      >
                        {formatNumberUS(projectArea, 0)} SF
                      </Text>
                    )}
                  </View>
                </Flex>
                <Flex direction={"row"} justifyContent={"space-between"}>
                  <Text>Configured Spaces Area</Text>
                  <Text>{formatNumberUS(progressWidth(), 0) + " SF"}</Text>
                </Flex>
                <Flex direction={"row"} justifyContent={"space-between"}>
                  {projectArea - progressWidth() > 0 && (
                    <Text>Remaining Space Area</Text>
                  )}
                  {projectArea - progressWidth() > 0 && (
                    <Text>
                      {formatNumberUS(projectArea - progressWidth(), 0) + " SF"}
                    </Text>
                  )}
                </Flex>
              </Flex>
              <Flex
                direction={"column"}
                // gap={"10px"}
                width={"100%"}
                position={"relative"}
                alignItems={"center"}
              >
                <Badge
                  position={"absolute"}
                  top={"40px"}
                  left={"5px"}
                  width={"fit-content"}
                  backgroundColor={"#40AABF"}
                  color={"#fff"}
                >
                  {formatNumberUS(progressWidth(), 0)}
                </Badge>
                <View
                  className="progressContainer"
                  height={"23px"}
                  width={"96%"}
                  border={"1px solid #c5c5c5"}
                  borderRadius={"10px"}
                >
                  <View
                    borderRadius={"10px"}
                    backgroundColor={
                      progressWidth("realValue") > 100 ? "red" : "lightblue"
                    }
                    height={"23px"}
                    style={{ transition: "0.5s ease" }}
                    width={progressWidth("width") + "%"}
                    textAlign={"center"}
                    fontSize={"12px"}
                    color={"#fff"}
                    position={"relative"}
                  >
                    <View
                      height={"29px"}
                      width={"29px"}
                      position={"absolute"}
                      right={"-17px"}
                      top={"-4px"}
                      backgroundColor={"#40aabf"}
                      borderRadius={"50%"}
                    ></View>
                    {progressWidth("width") + "%"}
                  </View>
                </View>
                <Badge
                  position={"absolute"}
                  top={"40px"}
                  right={"-5px"}
                  width={"fit-content"}
                  backgroundColor={"#40AABF"}
                  color={"#fff"}
                >
                  {formatNumberUS(projectArea, 0)}
                </Badge>
              </Flex>
            </Card>
          )}

          {selectedMarkets.length !== 0 && (
            <Card
              display={"flex"}
              gap="8px"
              direction={"column"}
              borderRadius="5px"
              border="1px solid #AEB3B7"
              backgroundColor={colorMode === "dark" && "#2e3436"}
              // boxShadow="0px -1px 0px 0px #B0B8BF inset"
              justifyContent="center"
              width={"100%"}
            >
              <Text
                textAlign={"center"}
                marginBottom={"5px"}
                fontWeight={"500"}
                fontSize={"20px"}
              >
                Selected Markets
              </Text>

              <Flex direction={"column"} gap={"10px"} width={"100%"}>
                {selectedMarkets.map((item, index) => (
                  <Badge
                    display={"flex"}
                    justifyContent="center"
                    key={index}
                    textAlign={"center"}
                  >
                    {properNaming(item)}
                  </Badge>
                ))}
              </Flex>
            </Card>
          )}
          {projectSpaces && Object.keys(projectSpaces).length !== 0 && (
            <Card
              display={"flex"}
              gap="8px"
              direction={"column"}
              borderRadius="5px"
              border="1px solid #AEB3B7"
              backgroundColor={colorMode === "dark" && "#2e3436"}
              // boxShadow="0px -1px 0px 0px #B0B8BF inset"
              justifyContent="center"
              width={"100%"}
            >
              <Text
                textAlign={"center"}
                marginBottom={"5px"}
                fontWeight={"500"}
                fontSize={"20px"}
              >
                Selected Spaces
              </Text>
              <Flex direction={"column"} gap={"10px"} width={"100%"}>
                {Object.keys(projectSpaces).map((space, index) => {
                  if (!uniqueName.includes(projectSpaces[space]["className"])) {
                    let spaceFilter = Object.keys(projectSpaces).filter(
                      (spaceObj) =>
                        projectSpaces[space]["className"] ===
                        projectSpaces[spaceObj]["className"]
                    );
                    uniqueName.push(projectSpaces[space]["className"]);
                    return (
                      <Flex
                        justifyContent="space-between"
                        key={index}
                        padding={"3px 8px"}
                        borderRadius={"20px"}
                        backgroundColor={
                          colorMode === "dark" ? "#c5c5c5" : "#EFF0F0"
                        }
                      >
                        <Badge>
                          {projectSpaces[space]["className"]} (
                          {spaceFilter.length})
                        </Badge>
                        <Badge backgroundColor={"#40AABF"} color={"#fff"}>
                          {formatNumberUS(totalSF(projectSpaces[space]['className']), 0)} SF
                        </Badge>
                      </Flex>
                    );
                  }
                })}
              </Flex>
            </Card>
          )}
          <View className="invisibleBlockSummary"></View>
        </ScrollView>
      </Card>
    </>
  );
};

export default ProjectSummaryFlexItem;
